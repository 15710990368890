<template>
  <v-text-field v-model="color" v-mask="mask" hide-details dense label="Culoare" outlined class="">
    <template v-slot:append>
      <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16">
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="color" flat :swatches="swatches" show-swatches />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'InlineColorPicker',
  props: {
    value: {
      default: '#00A4E1FF'
    }
  },
  data () {
    return {
      swatches: [
        ['#00a4e1'],
        ['#60a629'],
        ['#fcc129'],
        ['#a046fb'],
        ['#fb4739']
      ],
      mask: '!#XXXXXXXX',
      menu: false
    }
  },
  computed: {
    swatchStyle () {
      const {color, menu} = this
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    color:{
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
